import React from 'react';

interface Props {
  items: string[];
}


const Ul: React.FC<Props> = ({ items }) => {
  return (
  <ul className="pl-5 mt-3 space-y-3 list-disc">
    {items.map(item => <li key={item}>{item}</li>)}
  </ul>
  );
}

export default Ul;