import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "~components/layouts/Layout";
import LandingpagePersonalHeader from "~components/sections/landingpages/LandingpagePersonalHeader";
import SeoText from "~components/sections/landingpages/SeoText";
import Ul from "~components/typography/Ul";

export default function Page({ data, location }) {
	const keyword = "JAMStack Entwickler";
	const title = "JAMStack Entwickler: Schnelle Websites vom Profi";
	const description =
		"Ein JAMStack Entwickler baut Dir eine schnelle und skalierbare Website. Wir sind Dein Profi für das nächste JAMStack-Projekt!";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<LandingpagePersonalHeader
				title={title}
				description={description}
				keyword={keyword}
				schemaImage={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
				price="90"
				experienceYears="3"
				skills={["JavaScript", "Node", "Next", "Gatsby"]}
				siteUrl={data.site.siteMetadata.siteUrl}
				path={location.pathname}
			/>

			<SeoText
				headline="Was ist ein JAMStack Entwickler?"
				intro={[
					<>
						Der Begriff JAMStack ist relativ neu und unbekannt, vorrangig für Selbstständige, aber auch andere
						Entwickler. Der Begriff JAMStack beschreibt eine Architektur, wie eine Website aufgebaut werden kann. Wenn
						Du mehr dazu erfahren willst, besuche unseren Blog-Artikel{" "}
						<Link to="/blog/was-ist-jamstack-die-neue-architektur-fuer-websites/" className="underline">
							„Was ist JAMStack?“
						</Link>
						.
					</>,
					<>
						Als JAMStack Entwickler möchten wir Dir eine Dienstleistung anbieten, die sich auf bestimmte Technologien
						spezialisiert. Im Kern findest Du bei uns eine Lösung, die sowohl Frontend als auch das Backend einer
						Website für Dich realisieren kann. Die meisten Websites bauen wir in einem{" "}
						<Link to="/leistungen/javascript-entwickler/" className="underline">
							JavaScript
						</Link>{" "}
						Framework, zusammen mit einem{" "}
						<Link to="/blog/schnelle-websites-mit-dem-static-site-generator/" className="underline">
							Static Site Generator
						</Link>{" "}
						und einem Node.js Backend. So hast Du fließende Übergänge zwischen dem Techstack, was die grundlegende
						Programmiersprache angeht, hast aber eine Lösung für alle Bereiche.
					</>,
					"Die Community rundum den JAMStack wird täglich größer und immer mehr erfahrene Entwickler bauen auf diesen Ansatz auf. Der JAMStack steckt noch in den Kinderschuhen, verspricht aber einen großen Mehrwert. So vertrauen auch schon die Fortune-500-Unternehmen diesem Ansatz und Websites wie Audible, Bang & Olufsen, Hulu und viele mehr sind auf dem Ansatz entwickelt worden.",
				]}
			/>
			<SeoText
				headline="Worauf achtet ein JAMStack Entwickler?"
				intro={[
					"Ein JAMStack Entwickler kennzeichnet sich dadurch aus, dass er einen Techstack, also Technologien, benutzt, die bestimmte Vorteile mit sich bringen. Diese Technologien sind aber so Low-Level, dass sie eher die Möglichkeiten erweitern, statt sie einzuschränken. So können wir für Dich z.B. folgende Lösungen entwickeln:",
					<Ul
						items={[
							"B2B/Corporate/SaaS-Websites",
							"Clientseitig gerenderte Anwendungen (SPA/MPA) und Apps",
							"Benutzerdefinierte Landingpages",
							"Große Multi-User-Websites",
							"Persönliche Blogs",
							"Webportale",
							"Webshops",
						]}
					/>,
				]}
				text={[
					{
						headline: "Performance und Sicherheit",
						text: [
							"Viele der Websites, die ein JAMStack Entwickler baut, werden durch einen Static Site Generator realisiert. Dieser hat den Vorteil, dass er HTML-Dateien vor generiert werden und so eine Website schnell verfügbar gemacht wird, ohne dass beim Aufruf Serverkapazitäten gebraucht werden. Ein JavaScript-Framework erlaubt es zudem, dass sich die Website wie eine APP verhält und Seiten bei Bedarf auch gerne mal vorlädt. Da ergibt eine Website, die sich so schnell anfühlt, dass es gewissermaßen keine Ladezeiten gibt. Auch die Google Web Vitals bewerten dieses Vorgehen als zufriedenstellend und unsere Websites haben meist einen Performance-Score von fast 100 %.",
							"Der statische Ansatz erlaubt es auch, dass die Website an sich ein hohes Maß an Sicher verspricht, da die Auslieferung der Website nicht von einem Server oder einer Datenbank abhängt. Ein JAMStack-Entwickler muss also nur darauf achten, dass die APIs geschützt sind und Du hast so ein robustes und zuverlässiges Gesamtsystem.",
						],
					},
					{
						headline: "Skalierbarkeit",
						text: [
							"JAMStack-Websites haben den Vorteil, dass sie statisch sind und auf Microservices, Headless-CMS-Systemen und API-Schnittstellen aufbauen. So werden Sie extrem schlank gehalten und können nach Belieben skalieren. Eine Landingpage mit einer einzelnen Seite, oder eine Plattform mit über tausenden Seiten, ist gleichermaßen schnell und solide mit dem JAMStack-Ansatz realisierbar.  Du kannst Dich also darauf verlassen, dass JAMStack Entwickler Websites mit einem hohen Grad an Skalierbarkeit realisieren können. Diese Vorteile bringen Websites mit monolithischen CMS-Systemen wie WordPress zum Beispiel nicht.",
						],
					},
					{
						headline: "Anpassungsfähigkeit",
						text: [
							"JAMStack-Entwickler bauen Dir eine individuelle Website, die nicht aus dem Baukasten kommt. So hast Du eine Lösung, die komplett an Diene Bedürfnisse angepasst werden kann. Eine JAMStack-Anwendung hat keinen zentralen großen Server, auf dem das gesamte System läuft. Vielmehr werden viele voneinander unabhängige, aber in sich funktionale, Systeme genutzt. So werden Teillösungen geschaffen, die als API zur Verfügung gestellt werden und die Webentwicklung signifikant verändern. Entscheidest Du Dich später für ein Tool, welches Du benutzen willst, oder möchtest Du Deine Website durch eine Individual-Programmierung erweitern? Dann kann der JAMStack-Entwickler Deine Website nach Belieben anpassen. Egal, was die Zukunft bringt, mit einer JAMStack-Website hast Du ein Tool in der Hand, dass wandelbar ist und sich an Dein Business-Case anpasst.",
						],
					},
				]}
			/>
		</Layout>
	);
}

export const query = graphql`
	{
		seoImage: file(relativePath: { eq: "pages/leistungen/jamstack-entwickler/jamstack-entwickler_seo-image.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
